import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import realtyLogo from './assets/realty-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faTimes as faTimesLight, faBars as faBarsLight } from '@fortawesome/pro-light-svg-icons';
import { customSocials } from '~/constants/socials';
import Link from 'next/link';

const RealtyLiveLayout = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    const handleLoginClick = () => {
        window.open('https://buyer.realty.com.au/login', '_blank');
    };

    const handleAddListingClick = () => {
        window.open('https://www.realty.com.au/agency-sign-up', '_blank');
    };

    const handleLogoClick = () => {
        window.location.href = '/';
    };

    const handleButtonClick = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="w-full">
            <nav className="relative flex items-center justify-between bg-[#1b1b1b] px-5 py-4 shadow-md md:px-14">
                <div className="flex items-center">
                    <Image
                        src={realtyLogo}
                        alt="RealtyLive Logo"
                        width={168}
                        height={48}
                        className="object-contain object-left"
                        onClick={handleLogoClick}
                    />
                </div>

                <div className="hidden space-x-4 md:flex">
                    <button
                        className="flex h-14 w-full items-center justify-center rounded-lg border border-white bg-transparent text-base font-medium text-white transition-all hover:bg-white hover:bg-opacity-80 hover:text-black hover:shadow-lg hover:shadow-white/50 md:w-auto md:px-8"
                        onClick={handleAddListingClick}
                    >
                        Add your Listings
                    </button>
                    <button
                        className="flex h-14 w-full items-center justify-center rounded-lg bg-gradient-to-l from-purple-500 to-purple-900 text-base font-medium text-white transition-all hover:from-purple-600 hover:to-purple-800 hover:shadow-lg hover:shadow-purple-500/50 md:w-auto md:px-8"
                        onClick={handleLoginClick}
                    >
                        Login
                    </button>
                </div>

                <div className="md:hidden">
                    <button onClick={toggleMenu} aria-label="Toggle menu">
                        <FontAwesomeIcon
                            icon={isOpen ? faTimesLight : faBarsLight}
                            className="text-purple-500"
                            size="2x"
                        />
                    </button>
                </div>

                {isOpen && (
                    <div className="fixed inset-0 z-50 flex flex-col bg-[#1b1b1b] p-4 shadow-lg">
                        <div className="flex items-center justify-between">
                            <Image
                                src={realtyLogo}
                                alt="RealtyLive Logo"
                                width={168}
                                height={48}
                                className="object-contain object-left"
                                onClick={handleLogoClick}
                            />
                            <button onClick={toggleMenu} aria-label="Close menu">
                                <FontAwesomeIcon icon={faTimesLight} className="text-purple-500" size="2x" />
                            </button>
                        </div>
                        <div className="mt-16 flex flex-col gap-4">
                            <button
                                className="rounded-lg border px-5 py-3 text-base font-medium text-white"
                                onClick={handleAddListingClick}
                            >
                                Add your Listings
                            </button>
                            <button
                                className="rounded-lg bg-gradient-to-l from-purple-500 to-purple-900 px-5 py-3 text-base font-medium text-white"
                                onClick={handleLoginClick}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                )}
            </nav>

            <main className="flex justify-center ">{children}</main>

            <footer className="flex w-full flex-col items-center justify-center bg-[#292929]">
                <div className="w-full ">
                    <div className="flex flex-col  gap-6 px-5 py-12 md:justify-between md:px-16 md:py-16 lg:flex-row">
                        <div className="relative">
                            <Image
                                src={realtyLogo}
                                width={168}
                                height={48}
                                alt="RealtyLive Logo"
                                className="object-contain object-left"
                                onClick={handleLogoClick}
                            />

                            <div className="mt-6 flex w-full flex-wrap gap-4 ">
                                <button
                                    className="flex h-14 w-full items-center justify-center rounded-lg border border-white bg-transparent px-12 text-base font-medium text-white transition-all hover:bg-white hover:bg-opacity-80 hover:text-black hover:shadow-lg hover:shadow-white/50 md:w-auto"
                                    onClick={() =>
                                        handleButtonClick(
                                            'https://realty.pipedrive.com/scheduler/WJ2qDWfJ/realtycomau-demo'
                                        )
                                    }
                                >
                                    Request Demo
                                </button>
                                {/* <button className="flex  h-14 w-full items-center justify-center rounded-lg border text-base  font-medium text-white md:w-auto md:px-12">
                                    <FontAwesomeIcon icon={faApple} className="mr-2" size="2x" />
                                    App Store
                                </button>
                                <button className="flex h-14 w-full items-center justify-center rounded-lg border text-base font-medium text-white md:w-auto md:px-12">
                                    <FontAwesomeIcon icon={faGooglePlay} className="mr-2" size="lg" />
                                    Google Play
                                </button> */}
                            </div>
                        </div>

                        <div className="flex w-full flex-col justify-between gap-y-12 md:flex-row lg:w-2/3 xl:w-3/5">
                            <div className="w-full md:w-1/3">
                                <span className="font-semibold text-white ">ON MARKET LISTINGS</span>
                                <ul className="mt-3 space-y-3 text-sm font-light text-neutral-300">
                                    <li className="duration-300  hover:text-purple-500">
                                        <Link href="/buy#search">For Sale</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/rent#search">For Rent</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/sold#search">Sold</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/contact-agent">Agents</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full md:w-1/4">
                                <span className="font-semibold  text-white">QUICK LINKS</span>
                                <ul className="mt-3 space-y-3 text-sm font-light text-neutral-300">
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/privacy">Privacy Policy</Link>
                                    </li>
                                    <li className="duration-300 hover:text-purple-500">
                                        <Link href="/terms-of-service">Terms of Service</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="w-full md:w-2/5">
                                <span className="font-semibold text-white">SOCIAL</span>
                                <div className="mt-3 flex flex-wrap gap-4 text-neutral-300">
                                    {customSocials.map(({ link, title, icon }) => (
                                        <a
                                            className="h-6 w-6"
                                            key={link}
                                            href={link}
                                            title={title}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={icon}
                                                alt={title}
                                                className="h-full w-full object-contain invert filter "
                                            />
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center py-4  text-sm text-white"></div>
            </footer>
        </div>
    );
};

export default RealtyLiveLayout;
