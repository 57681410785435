import React from 'react';
import styles from './CloseSvg.module.scss';

interface CloseSvgProps {
    isHovered: boolean;
    scrolling: boolean;
    isMobile: boolean;
}

const CloseSvg = ({ isHovered, scrolling, isMobile }: CloseSvgProps) => {
    const shouldShake = isMobile && scrolling;

    return (
        <svg
            className={`${styles.close} ${shouldShake || isHovered ? styles.shake : ''}`}
            width="126"
            height="104"
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 126 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.2843 58.1083L3.01632 46.5223C-3.77968 41.0043 1.80832 26.8543 10.8413 15.7273C19.8743 4.60126 32.5723 -3.77574 39.3713 1.74426C39.7133 2.02126 53.6893 13.2223 61.0323 19.1063L57.2803 23.7893C56.4133 23.0943 36.0313 6.76226 35.5903 6.40426C33.6543 4.83326 24.8123 8.03826 15.4993 19.5093C6.18532 30.9823 4.86532 40.2953 6.79732 41.8633L21.0663 53.4493L17.2843 58.1083Z"
                fill="white"
            />
            <path
                d="M70.7443 102.877C68.4763 102.877 66.1953 102.129 64.3013 100.593L61.9803 98.7075C60.6943 97.6625 60.4993 95.7735 61.5433 94.4875C62.5873 93.2015 64.4773 93.0065 65.7633 94.0505L68.0833 95.9355C69.8963 97.4075 72.5713 97.1295 74.0443 95.3155C75.5173 93.5015 75.2393 90.8275 73.4263 89.3555C72.1403 88.3115 71.9443 86.4215 72.9883 85.1355C74.0323 83.8505 75.9233 83.6545 77.2083 84.6975L79.7403 86.7525C80.6193 87.4675 81.7253 87.7935 82.8483 87.6785C83.9743 87.5615 84.9873 87.0135 85.7003 86.1355C86.4133 85.2575 86.7413 84.1535 86.6253 83.0275C86.5083 81.9045 85.9623 80.8945 85.0883 80.1815L85.0813 80.1755C83.7953 79.1315 83.5993 77.2425 84.6433 75.9565C85.6873 74.6705 87.5773 74.4745 88.8623 75.5175C88.8663 75.5205 88.8703 75.5235 88.8743 75.5275L91.3953 77.5755C93.2094 79.0465 95.8833 78.7695 97.3573 76.9555C98.0703 76.0765 98.3993 74.9735 98.2823 73.8485C98.1653 72.7225 97.6173 71.7095 96.7383 70.9965C95.4523 69.9525 95.2563 68.0625 96.3003 66.7765C97.3453 65.4915 99.2353 65.2955 100.52 66.3385C100.524 66.3415 100.528 66.3445 100.532 66.3485L103.054 68.3965C103.932 69.1095 105.035 69.4385 106.161 69.3205C107.286 69.2035 108.299 68.6555 109.011 67.7775C110.485 65.9635 110.207 63.2895 108.394 61.8165L83.3133 41.4495C82.0273 40.4055 81.8313 38.5155 82.8753 37.2295C83.9193 35.9435 85.8093 35.7475 87.0953 36.7915L112.178 57.1575C116.56 60.7165 117.23 67.1765 113.671 71.5575C111.949 73.6795 109.503 75.0045 106.782 75.2865C105.932 75.3755 105.088 75.3585 104.264 75.2415C104.08 77.2415 103.31 79.1435 102.015 80.7375C99.6653 83.6305 96.0513 84.9065 92.6073 84.4145C92.4243 86.4175 91.6543 88.3205 90.3593 89.9165C88.6353 92.0395 86.1883 93.3625 83.4693 93.6455C82.6173 93.7345 81.7713 93.7175 80.9453 93.5985C80.7653 95.5475 80.0263 97.4655 78.7033 99.0955C76.6823 101.585 73.7253 102.877 70.7443 102.877Z"
                fill="white"
            />
            <path
                d="M23.2455 58.9873C22.3745 58.9873 21.5745 58.4143 21.3235 57.5353C18.5395 47.7823 20.6885 37.2633 27.0725 29.3993C35.4955 19.0243 49.5615 15.1933 62.0745 19.8763C63.1095 20.2633 63.6345 21.4153 63.2465 22.4503C62.8595 23.4843 61.7085 24.0113 60.6725 23.6223C49.7715 19.5453 37.5165 22.8813 30.1775 31.9213C24.6155 38.7733 22.7425 47.9383 25.1695 56.4383C25.4725 57.5003 24.8575 58.6073 23.7955 58.9103C23.6115 58.9623 23.4265 58.9873 23.2455 58.9873Z"
                fill="white"
            />
            <path
                d="M31.736 84.8484C31.378 84.8484 31.02 84.8294 30.659 84.7924C27.939 84.5094 25.492 83.1854 23.768 81.0614C22.473 79.4684 21.703 77.5674 21.52 75.5664C20.697 75.6834 19.853 75.7024 19.003 75.6124C16.283 75.3304 13.835 74.0064 12.113 71.8834C10.39 69.7624 9.59603 67.0944 9.87803 64.3734C10.16 61.6534 11.485 59.2064 13.607 57.4834C14.893 56.4404 16.782 56.6354 17.827 57.9214C18.871 59.2074 18.675 61.0974 17.389 62.1414C16.511 62.8544 15.963 63.8674 15.846 64.9934C15.729 66.1194 16.058 67.2234 16.771 68.1024C17.484 68.9804 18.496 69.5284 19.622 69.6454C20.752 69.7614 21.851 69.4334 22.729 68.7204L25.264 66.6634C26.55 65.6184 28.44 65.8164 29.484 67.1024C30.529 68.3884 30.332 70.2774 29.046 71.3224C28.167 72.0354 27.619 73.0494 27.502 74.1744C27.385 75.2994 27.713 76.4024 28.427 77.2794C29.141 78.1594 30.155 78.7084 31.281 78.8254C32.404 78.9414 33.51 78.6134 34.389 77.9014L36.922 75.8454C38.208 74.8014 40.097 74.9974 41.142 76.2834C42.186 77.5694 41.99 79.4584 40.703 80.5034L38.171 82.5594C36.328 84.0534 34.076 84.8484 31.736 84.8484Z"
                fill="white"
            />
            <path
                d="M55.0497 103.209C54.6917 103.209 54.3327 103.19 53.9727 103.153C51.2527 102.87 48.8057 101.545 47.0817 99.4223C43.5247 95.0393 44.1947 88.5793 48.5767 85.0233C49.8627 83.9793 51.7517 84.1753 52.7967 85.4623C53.8417 86.7483 53.6447 88.6383 52.3587 89.6823C50.5457 91.1543 50.2687 93.8283 51.7417 95.6423C52.4547 96.5213 53.4677 97.0693 54.5937 97.1863C55.7147 97.3033 56.8227 96.9743 57.7027 96.2613C58.9877 95.2163 60.8787 95.4133 61.9227 96.6993C62.9677 97.9853 62.7707 99.8743 61.4847 100.919C59.6407 102.413 57.3897 103.209 55.0497 103.209Z"
                fill="white"
            />
            <path
                d="M107.1 58.9532C107.06 58.9532 107.02 58.9522 106.979 58.9502C105.876 58.8842 105.036 57.9372 105.101 56.8352C105.638 47.8172 102.21 39.3152 95.1877 32.2492C89.1117 26.1392 80.2287 23.0222 70.1777 23.4472C69.0787 23.5062 68.1407 22.6392 68.0937 21.5352C68.0457 20.4312 68.9017 19.4982 70.0057 19.4512C81.1977 18.9692 91.1457 22.5112 98.0257 29.4302C105.874 37.3272 109.701 46.8862 109.095 57.0742C109.031 58.1342 108.151 58.9532 107.1 58.9532Z"
                fill="white"
            />
            <path
                d="M49.2219 61.8841C48.7619 61.8841 48.2969 61.8541 47.8289 61.7931C43.6309 61.2421 40.5829 58.2631 39.8759 54.0191C38.6049 46.3891 40.2769 35.7441 43.6829 29.7831C46.5109 24.8361 52.1139 21.2231 60.8109 18.7381C63.1119 18.0811 64.8289 17.7421 66.9969 17.5351C73.9459 12.0031 86.0509 2.36506 86.4159 2.06906C93.2099 -3.44594 105.911 4.92905 114.942 16.0541C123.975 27.1801 129.564 41.3301 122.769 46.8481L108.35 58.5551C107.062 59.5981 105.174 59.4021 104.13 58.1171C103.086 56.8311 103.282 54.9411 104.568 53.8971L118.986 42.1911C120.919 40.6211 119.599 31.3081 110.284 19.8361C100.971 8.36406 92.1299 5.16006 90.1949 6.72905C89.7479 7.09205 70.8279 22.1541 70.0229 22.7951C69.5519 23.1701 68.9789 23.3951 68.3789 23.4401C66.1659 23.6061 64.6719 23.8761 62.4589 24.5081C55.4399 26.5141 50.8749 29.2901 48.8919 32.7611C46.1749 37.5171 44.7549 46.8011 45.7939 53.0341C46.0699 54.6901 47.0169 55.6361 48.6099 55.8451C50.2039 56.0521 51.6439 55.3631 51.9969 54.5881C53.7849 50.6611 56.0169 41.7621 56.0389 41.6731C56.3839 40.2911 57.6529 39.3551 59.0759 39.4021C59.1459 39.4061 66.1519 39.6901 72.0469 39.0341C78.4949 38.3181 83.6449 36.5201 83.6959 36.5021C85.2549 35.9501 86.9709 36.7691 87.5239 38.3301C88.0759 39.8911 87.2599 41.6041 85.6999 42.1581C85.4659 42.2411 79.8789 44.2021 72.7099 44.9971C68.5239 45.4631 63.9649 45.4931 61.2519 45.4581C60.4189 48.5531 58.8699 53.9731 57.4589 57.0711C56.1179 60.0221 52.8479 61.8841 49.2219 61.8841Z"
                fill="white"
            />
            <path
                d="M43.3926 94.0282C43.0356 94.0282 42.6756 94.0092 42.3156 93.9722C39.5956 93.6902 37.1486 92.3662 35.4246 90.2432C33.7006 88.1202 32.9076 85.4532 33.1896 82.7332C33.4716 80.0122 34.7966 77.5662 36.9196 75.8432C36.9586 75.8112 36.9986 75.7812 37.0386 75.7522C38.8406 73.7492 41.4406 72.4572 44.1806 72.2032C47.0956 71.9392 49.8396 72.8062 51.9206 74.6572C53.9896 76.4982 55.2176 79.0342 55.3786 81.7992C55.5396 84.5632 54.6146 87.2242 52.7746 89.2922C52.6636 89.4172 52.5426 89.5322 52.4116 89.6372L49.8246 91.7362C47.9846 93.2322 45.7326 94.0282 43.3926 94.0282ZM41.0106 80.2132C40.9156 80.3152 40.8126 80.4122 40.7006 80.5022C39.8226 81.2142 39.2746 82.2272 39.1586 83.3522C39.0416 84.4782 39.3706 85.5812 40.0836 86.4602C40.7966 87.3372 41.8106 87.8862 42.9366 88.0032C44.0666 88.1192 45.1666 87.7922 46.0436 87.0782L48.4316 85.1402C49.1146 84.2942 49.4536 83.2402 49.3896 82.1482C49.3216 80.9832 48.8046 79.9152 47.9336 79.1402C47.1076 78.4062 45.9756 78.0662 44.7356 78.1782C43.4726 78.2952 42.2866 78.8812 41.4826 79.7842C41.3376 79.9472 41.1796 80.0902 41.0106 80.2132Z"
                fill="white"
            />
            <path
                d="M39.582 80.2683C38.872 80.2683 38.159 80.0183 37.587 79.5083C36.35 78.4063 36.24 76.5093 37.342 75.2723C38.104 74.4173 38.487 73.3163 38.42 72.1733C38.354 71.0293 37.845 69.9803 36.989 69.2183C36.133 68.4563 35.028 68.0663 33.887 68.1403C32.743 68.2063 31.693 68.7143 30.931 69.5703C29.829 70.8083 27.933 70.9173 26.696 69.8163C25.458 68.7143 25.348 66.8183 26.45 65.5813C30.223 61.3423 36.74 60.9643 40.977 64.7363C43.03 66.5623 44.249 69.0803 44.409 71.8233C44.569 74.5683 43.65 77.2093 41.822 79.2623C41.23 79.9283 40.408 80.2683 39.582 80.2683Z"
                fill="white"
            />
            <path
                d="M61.5864 99.8352C60.8764 99.8352 60.1634 99.5852 59.5914 99.0752C58.3544 97.9732 58.2444 96.0772 59.3464 94.8392C60.1074 93.9852 60.4904 92.8842 60.4234 91.7412C60.3574 90.5972 59.8484 89.5472 58.9924 88.7852C58.1374 88.0242 57.0324 87.6402 55.8914 87.7082C54.7474 87.7752 53.6974 88.2832 52.9364 89.1382C51.8344 90.3762 49.9364 90.4862 48.7014 89.3852C47.4634 88.2832 47.3524 86.3872 48.4544 85.1502C52.2254 80.9112 58.7424 80.5302 62.9814 84.3032C65.0354 86.1312 66.2534 88.6492 66.4134 91.3922C66.5734 94.1372 65.6544 96.7782 63.8274 98.8302C63.2344 99.4962 62.4124 99.8352 61.5864 99.8352Z"
                fill="white"
            />
            <path
                d="M26.8603 71.9451C26.1503 71.9451 25.4383 71.6941 24.8663 71.1851C23.6293 70.0831 23.5193 68.1871 24.6213 66.9501C25.3823 66.0961 25.7643 64.9951 25.6973 63.8521C25.6303 62.7071 25.1223 61.6581 24.2653 60.8961C22.4983 59.3251 19.7823 59.4821 18.2093 61.2491C17.1073 62.4881 15.2113 62.5961 13.9743 61.4951C12.7363 60.3931 12.6263 58.4971 13.7283 57.2601C17.5013 53.0221 24.0173 52.6421 28.2543 56.4151C30.3073 58.2421 31.5273 60.7591 31.6873 63.5031C31.8483 66.2481 30.9293 68.8891 29.1023 70.9421C28.5093 71.6051 27.6863 71.9451 26.8603 71.9451Z"
                fill="white"
            />
            <path
                d="M48.9865 52.614C48.0265 52.614 47.1795 51.921 47.0165 50.942C46.7805 49.525 44.5805 48.698 44.0045 48.554C42.9325 48.286 42.2815 47.2 42.5495 46.129C42.8185 45.057 43.9035 44.406 44.9745 44.674C46.9325 45.164 50.3975 46.897 50.9625 50.286C51.1445 51.375 50.4085 52.406 49.3185 52.587C49.2075 52.605 49.0965 52.614 48.9865 52.614Z"
                fill="white"
            />
        </svg>
    );
};
export default CloseSvg;
