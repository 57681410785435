import React from 'react';
import styles from './AlertsSvg.module.scss';
interface AlertsSvgProps {
    isHovered: boolean;
    scrolling: boolean;
    isMobile: boolean;
}

const AlertsSvg = ({ isHovered, scrolling, isMobile }: AlertsSvgProps) => {
    const shouldRing = isMobile && scrolling;

    return (
        <svg
            className={`${styles.alerts} ${shouldRing || isHovered ? styles.ring : ''}`}
            width="107"
            height="97"
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 107 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M74.7395 75.0002C68.1395 72.5002 59.3395 70.8001 49.9395 70.2001C34.0395 69.2001 20.3395 71.6001 15.0395 76.2001C13.5395 77.5001 12.7395 79.1001 12.6395 80.7001C12.4395 84.6001 16.1395 88.1002 23.5395 90.9002C30.1395 93.4002 38.9395 95.1001 48.3395 95.7001C64.2395 96.7001 77.9395 94.3001 83.2395 89.7001C84.7395 88.4001 85.5395 86.8001 85.6395 85.2001C85.8395 81.3001 82.1395 77.8001 74.7395 75.0002ZM49.6395 74.2001C51.8395 74.3001 54.0395 74.5002 56.2395 74.8002C55.9395 78.6002 52.6395 81.4001 48.8395 81.1001C46.9395 81.0001 45.2395 80.1001 44.0395 78.7001C42.8395 77.4001 42.2395 75.7002 42.3395 73.9002C44.6395 73.9002 47.1395 73.9001 49.7395 74.1001L49.6395 74.2001ZM80.5395 86.8002C76.9395 89.9002 65.4395 92.9002 48.5395 91.8002C39.5395 91.2002 31.1395 89.6001 24.9395 87.2001C18.6395 84.8001 16.4395 82.3002 16.5395 80.9002C16.5395 80.3002 16.9395 79.8001 17.6395 79.1001C20.1395 76.9001 26.6395 74.8001 36.2395 74.1001C36.2395 77.3001 37.3395 80.3001 39.4395 82.7001C41.7395 85.3001 44.9395 86.9001 48.3395 87.1001C51.9395 87.3001 55.2395 86.1002 57.7395 83.9002C60.0395 81.9002 61.6395 79.0001 62.0395 75.7001C66.2395 76.5001 69.9395 77.5001 73.1395 78.7001C79.4395 81.1001 81.6395 83.6002 81.5395 85.0002C81.5395 85.5002 81.1395 86.1002 80.4395 86.8002H80.5395Z"
                fill="white"
            />
            <path
                d="M31.9389 28.1C31.9389 28.1 31.8389 28.1 31.7389 28.1C33.4389 25.7 35.7389 22.8 38.4389 20.4C38.7389 20.4 39.0389 20.2 39.3389 20.1C40.7389 19.7 42.2389 20.3 42.8389 21.6C43.5389 22.9 42.6389 24.1 41.5389 25C38.4389 27.4 36.0389 31.3 34.2389 35.9C35.9389 31.5 38.3389 27.8 41.2389 25.3C44.6389 22.3 48.7389 20.9 53.3389 21.2C62.2389 21.8 67.6389 28.5 70.1389 37.2C68.9389 32.3 66.8389 28.4 64.0389 25.6C62.9389 24.6 62.8389 22.9 63.7389 21.7C64.6389 20.5 66.2389 20.2 67.6389 20.9H67.7389C70.0389 23 72.2389 26.1 74.2389 30.4C76.8389 36.6 77.9389 44.5 77.3389 53.4C76.8389 61.1 78.9389 67.8 81.3389 72.8C79.1389 67.9 77.2389 61.4 77.7389 53.8C78.9389 29.3 69.5389 16.1 53.8389 15.1C47.7389 14.7 42.0389 16.7 37.4389 20.7C30.5389 26.8 26.1389 37.7 25.3389 50.5C25.3389 52 25.0389 53.4 24.8389 54.8C25.1389 53.3 25.3389 51.8 25.4389 50.2C25.9389 41.9 28.4389 34 32.1389 28L31.9389 28.1Z"
                fill="white"
            />
            <path
                d="M71.3387 53.0001C70.8387 61.5001 72.9387 68.8001 75.4387 74.4001C73.0387 68.9001 71.1387 61.7001 71.6387 53.4001C72.0387 47.8001 71.5387 42.1001 70.1387 37.1001C71.3387 41.7001 71.7387 47.0001 71.3387 52.9001V53.0001Z"
                fill="white"
            />
            <path
                d="M34.2387 35.8999C32.5387 40.2999 31.4387 45.3999 31.1387 50.9999C31.1387 52.4999 30.8387 53.8999 30.6387 55.1999C30.9387 53.6999 31.0387 52.2999 31.1387 50.6999C31.4387 45.3999 32.5387 40.2999 34.2387 35.8999Z"
                fill="white"
            />
            <path
                d="M87.8387 83.1C87.7387 82.7 87.4387 82.3 87.2387 82.1C87.2387 82.1 84.0387 78.6 81.3387 72.9C84.0387 78.8 87.1387 82.4 87.2387 82.4C88.0387 83.3 88.2387 84.6 87.7387 85.7C87.5387 86.1 87.3387 86.4 87.0387 86.7C86.4387 87.2 85.6387 87.5 84.8387 87.4L78.4387 87C72.8387 89.5 62.0387 91.3 49.0387 90.5C40.2387 90 31.5387 88.3 25.2387 85.9C22.5387 84.9 20.4387 84 18.8387 83.3L14.0387 83C12.8387 83 11.7387 82.1 11.3387 81C10.9387 79.8 11.3387 78.5 12.2387 77.8C12.7387 77.4 22.2387 68.8 24.7387 55C22.1387 68.7 12.7387 77.1 12.3387 77.5C11.9387 77.9 11.5387 78.4 11.4387 79C11.4387 79.2 11.2387 79.6 11.1387 80.3C10.9387 83.3 12.8387 87.8 23.1387 91.7C30.0387 94.3 39.1387 96.1 48.6387 96.7C64.6387 97.7 79.6387 94.9 85.3387 89.9C87.5387 88 88.0387 86 88.1387 84.7C88.1387 84.2 88.1387 83.7 87.9387 83.3L87.8387 83.1Z"
                fill="white"
            />
            <path
                d="M12.139 77.7001C11.239 78.5 10.839 79.8001 11.239 80.9001C11.639 82.1001 12.639 82.9001 13.939 82.9001L18.739 83.2001C17.139 82.4001 16.139 81.8001 15.839 81.4001C18.839 78.5001 28.139 69.0001 30.639 55.2001C30.839 53.8001 31.039 52.4001 31.139 51.0001C31.439 45.4001 32.539 40.3001 34.239 35.9001C35.939 31.3001 38.439 27.5001 41.539 25.0001C42.639 24.1001 43.539 22.9001 42.839 21.6001C42.139 20.3001 40.639 19.7001 39.339 20.1001C39.039 20.1001 38.739 20.3001 38.439 20.4001C35.739 22.8001 33.439 25.7001 31.739 28.1001C31.739 28.1001 31.839 28.1001 31.939 28.1001C28.139 34.1001 25.739 42.1001 25.239 50.3001C25.239 51.9001 24.939 53.4001 24.639 54.9001C22.139 68.7001 12.639 77.3 12.139 77.7001Z"
                fill="white"
            />
            <path
                d="M77.2387 53.4001C77.7387 44.5001 76.7387 36.7001 74.1387 30.4001C72.1387 26.1001 69.9387 23.0001 67.6387 20.8001C67.6387 20.8001 67.6388 20.8001 67.5388 20.8001C66.2388 20.1001 64.5387 20.4001 63.6387 21.6001C62.7387 22.8001 62.8388 24.5001 63.9388 25.5001C66.7388 28.3001 68.8387 32.3001 70.0388 37.1001C71.4388 42.1001 71.9388 47.8001 71.5388 53.4001C71.0388 61.7001 72.9388 68.8001 75.3388 74.4001C77.5388 79.3001 80.1387 83.0001 81.6387 84.8001C81.6387 84.9001 81.4387 85.0001 81.2387 85.2001C80.5387 85.8001 79.5388 86.4001 78.3388 87.0001L84.7387 87.4001C85.5387 87.4001 86.3388 87.2001 86.9388 86.7001C87.2388 86.4001 87.4387 86.1001 87.6387 85.7001C88.1387 84.6001 87.9387 83.3001 87.1387 82.4001C87.1387 82.4001 83.9387 78.8001 81.2387 72.9001C78.8387 67.9001 76.7387 61.2001 77.2387 53.5001V53.4001Z"
                fill="white"
            />
            <path
                d="M87.139 82.4002C87.039 82.3002 76.539 70.5002 77.639 53.9002C78.639 34.1002 72.639 21.7002 62.039 17.1002L62.439 12.6002C62.539 10.5002 61.839 8.40022 60.439 6.80022C59.039 5.20022 57.039 4.20022 54.939 4.10022C52.839 4.00022 50.739 4.70022 49.139 6.10022C47.539 7.50022 46.539 9.50022 46.439 11.6002L46.039 15.9002C42.839 16.8002 39.839 18.4002 37.239 20.7002C30.339 26.8002 25.939 37.7002 25.139 50.5002C24.139 66.8002 12.639 77.1002 12.139 77.6002C11.239 78.4002 10.839 79.7002 11.239 80.8002C11.639 82.0002 12.639 82.8002 13.939 82.8002H14.439C17.739 80.4002 20.139 77.7002 21.539 76.0002C25.539 70.7002 30.439 62.0002 31.139 50.8002C31.839 39.5002 35.539 30.2002 41.239 25.1002C42.539 23.9002 43.939 23.0002 45.439 22.3002C46.739 21.7002 48.139 21.3002 49.539 21.1002C50.739 20.9002 51.939 20.9002 53.239 20.9002C54.739 20.9002 56.239 21.3002 57.539 21.7002C58.939 22.2002 60.239 22.8002 61.339 23.6002C69.239 28.9002 72.339 41.3002 71.539 53.3002C70.739 65.4002 75.239 75.1002 78.739 80.8002C80.139 82.8002 83.239 84.8002 86.739 86.5002C87.039 86.2002 87.239 85.9002 87.439 85.5002C87.939 84.4002 87.739 83.1002 86.939 82.2002L87.139 82.4002ZM58.139 15.8002C56.739 15.5002 55.239 15.2002 53.639 15.1002C52.439 15.1002 51.239 15.1002 50.139 15.1002L50.439 11.7002C50.439 10.6002 50.939 9.70022 51.739 8.90022C52.539 8.20022 53.539 7.80022 54.639 7.90022C55.739 7.90022 56.639 8.40022 57.439 9.30022C58.239 10.2002 58.539 11.1002 58.439 12.2002L58.139 15.6002V15.8002Z"
                fill="white"
            />
            <path
                d="M91.0394 36.8998C89.4394 36.3998 88.5394 34.7998 89.0394 33.1998C91.2394 25.8998 87.1394 18.1998 79.8394 15.9998C78.2394 15.4998 77.3394 13.8998 77.8394 12.2998C78.3394 10.6998 79.9394 9.79983 81.5394 10.2998C92.0394 13.4998 97.9394 24.4998 94.7394 34.9998C94.2394 36.5998 92.5394 37.4998 91.0394 36.9998V36.8998Z"
                fill="white"
            />
            <path
                d="M101.039 39.9002C99.4394 39.4002 98.5394 37.8002 99.0394 36.2002C102.939 23.4002 95.6394 9.70022 82.8394 5.90022C81.2394 5.40022 80.3394 3.80022 80.8394 2.20022C81.3394 0.60022 82.9394 -0.29978 84.5394 0.20022C100.539 5.00022 109.639 22.0002 104.839 38.0002C104.339 39.6002 102.639 40.5002 101.139 40.0002L101.039 39.9002Z"
                fill="white"
            />
            <path
                d="M14.8394 37.0001C13.2394 37.4001 11.6394 36.5001 11.1394 34.9001C8.33939 24.3001 14.7394 13.5001 25.3394 10.7001C26.9394 10.3001 28.5394 11.2001 29.0394 12.8001C29.4394 14.4001 28.5394 16.0001 26.9394 16.5001C19.5394 18.4001 15.1394 26.0001 17.0394 33.4001C17.4394 35.0001 16.5394 36.6001 14.9394 37.1001L14.8394 37.0001Z"
                fill="white"
            />
            <path
                d="M4.63905 39.5998C3.03905 39.9998 1.43905 39.0998 0.93905 37.4998C-3.16095 21.2998 6.53905 4.69979 22.739 0.399794C24.3391 -0.000206351 25.939 0.899794 26.439 2.49979C26.839 4.09979 25.939 5.69979 24.339 6.19979C11.239 9.59979 3.43905 22.9998 6.83905 35.9998C7.23905 37.5998 6.33905 39.1998 4.73905 39.6998L4.63905 39.5998Z"
                fill="white"
            />
        </svg>
    );
};
export default AlertsSvg;
