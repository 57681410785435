import React from 'react';
import styles from './ShareSvg.module.scss';
interface ShareSvgProps {
    isHovered: boolean;
    scrolling: boolean;
    isMobile: boolean;
}

const ShareSvg = ({ isHovered, scrolling, isMobile }: ShareSvgProps) => {
    // Trigger shaking only if on mobile and scrolled
    const shouldSwing = isMobile && scrolling;

    return (
        <svg
            className={`${styles.share} ${shouldSwing || isHovered ? styles.swing : ''}`}
            width="101"
            height="103"
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 101 103"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M42.2719 63.3069C42.2398 63.3069 42.2069 63.3059 42.1739 63.3049L2.90185 61.3789C2.01785 61.3349 1.26785 60.7169 1.05685 59.8579C0.845848 58.9989 1.22485 58.1029 1.98885 57.6559L96.9198 2.03892C97.7798 1.53392 98.8858 1.74292 99.5028 2.52892C100.121 3.31492 100.064 4.43592 99.3678 5.15492L43.7089 62.6969C43.3309 63.0879 42.8129 63.3069 42.2719 63.3069ZM9.80385 57.7119L41.4638 59.2649L86.3338 12.8749L9.80385 57.7119Z"
                fill="white"
            />
            <path
                d="M89.4305 101.726C88.9015 101.726 88.3825 101.515 87.9985 101.122L55.2675 67.5872C54.6105 66.9132 54.5115 65.8722 55.0315 65.0872L96.0385 3.11318C96.3105 2.32218 97.0605 1.76318 97.9305 1.76318C97.9345 1.76318 97.9395 1.76318 97.9435 1.76318C98.9855 1.77018 99.8465 2.57418 99.9245 3.61318L99.9325 3.71718C99.9475 3.91418 99.9335 4.11118 99.8905 4.30118L91.4235 99.9032C91.3545 100.681 90.8385 101.347 90.1035 101.61C89.8825 101.688 89.6565 101.726 89.4305 101.726ZM59.2575 65.9482L87.8215 95.2152L95.2285 11.5852L59.2575 65.9482Z"
                fill="white"
            />
            <path
                d="M49.8163 93.0569C49.7863 93.0569 49.7563 93.0559 49.7263 93.0549C48.8103 93.0139 48.0383 92.3559 47.8553 91.4559L41.5623 60.6039C41.3413 59.5219 42.0403 58.4649 43.1233 58.2449C44.2123 58.0239 45.2623 58.7229 45.4823 59.8059L50.1803 82.8409L55.7653 64.1339C56.0803 63.0759 57.1913 62.4699 58.2533 62.7899C59.3123 63.1049 59.9133 64.2199 59.5973 65.2779L51.7313 91.6289C51.4783 92.4789 50.6963 93.0569 49.8163 93.0569Z"
                fill="white"
            />
            <path
                d="M49.8149 93.0568C49.4869 93.0568 49.1569 92.9768 48.8529 92.8098C48.0369 92.3618 47.6379 91.4088 47.8909 90.5118L55.1039 65.0338C55.3019 64.3348 55.8639 63.7978 56.5719 63.6318C57.2769 63.4698 58.0219 63.6978 58.5099 64.2358L66.3349 72.8568C67.0239 73.6138 67.0279 74.7688 66.3469 75.5318L51.3089 92.3878C50.9179 92.8248 50.3699 93.0568 49.8149 93.0568ZM57.9709 69.5928L54.1069 83.2428L62.1639 74.2118L57.9709 69.5928Z"
                fill="white"
            />
            <path
                d="M89.4312 102.726C88.6602 102.726 87.9052 102.429 87.3342 101.872L64.0612 79.1518L52.0852 93.0168C51.3542 93.8628 50.2112 94.2308 49.1252 93.9758C48.0362 93.7178 47.1822 92.8768 46.9062 91.7928L39.3622 62.0428C38.9552 60.4378 39.9262 58.8048 41.5332 58.3968C43.1372 57.9908 44.7712 58.9618 45.1792 60.5678L51.3082 84.7378L61.6052 72.8158C62.1462 72.1898 62.9212 71.8148 63.7472 71.7798C64.5872 71.7398 65.3792 72.0518 65.9712 72.6298L86.9992 93.1578L94.9282 3.63078C95.0742 1.98078 96.5402 0.76478 98.1812 0.90678C99.8312 1.05378 101.051 2.50878 100.905 4.15978L92.4192 99.9878C92.3172 101.142 91.5592 102.135 90.4722 102.537C90.1322 102.665 89.7802 102.726 89.4312 102.726Z"
                fill="white"
            />
            <path
                d="M42.2745 64.307C42.2245 64.307 42.1745 64.306 42.1255 64.303L2.85348 62.377C1.52748 62.313 0.402476 61.384 0.0864761 60.096C-0.229524 58.807 0.339476 57.463 1.48348 56.792L96.4145 1.17504C97.8455 0.337036 99.6825 0.819035 100.52 2.24704C101.357 3.67704 100.877 5.51403 99.4485 6.35203L13.2085 56.877L42.4205 58.31C44.0755 58.391 45.3515 59.798 45.2705 61.453C45.1895 63.059 43.8635 64.307 42.2745 64.307Z"
                fill="white"
            />
            <path
                d="M97.9145 6.88018C97.5795 6.88018 97.2405 6.82318 96.9115 6.70518C95.6225 6.24218 94.8005 4.97818 94.9305 3.61418L94.9435 3.48018C95.0895 1.93818 96.3835 0.763184 97.9285 0.763184C97.9395 0.763184 97.9495 0.763184 97.9585 0.763184C99.5165 0.779184 100.803 1.98418 100.92 3.53718L100.928 3.64118C100.979 4.31618 100.8 4.98918 100.42 5.54918C99.8475 6.39618 98.8965 6.88018 97.9145 6.88018Z"
                fill="white"
            />
        </svg>
    );
};
export default ShareSvg;
