import React, { useState, useEffect } from 'react';
import briefMockup from './assets/briefsmockup.png';
import alertsMockup from './assets/alertsmockup.png';
import closeMockup from './assets/closemockup.png';

import BriefSvg from './assets/svg/BriefSvg';
import AlertsSvg from './assets/svg/AlertsSvg';
import CloseSvg from './assets/svg/CloseSvg';

const ForBuyingAgent = () => {
    const [hoveredBrief, setHoveredBrief] = useState(false);
    const [hoveredAlerts, setHoveredAlerts] = useState(false);
    const [hoveredClose, setHoveredClose] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="flex w-full flex-col items-center gap-12 px-4 py-8 md:px-10 lg:px-20 lg:py-16">
            <div className="flex w-full justify-center gap-2 text-center">
                <h1 className="text-4xl font-semibold text-white md:text-5xl lg:text-6xl">For Buying Agents</h1>
            </div>

            <div className="flex flex-col gap-4 md:flex-row lg:gap-10">
                <div
                    className="flex w-full flex-col overflow-hidden rounded-2xl bg-[#292929] shadow-lg"
                    onMouseEnter={() => setHoveredBrief(true)}
                    onMouseLeave={() => setHoveredBrief(false)}
                >
                    <div className="h-72 w-full flex-shrink-0">
                        <img src={briefMockup} className="h-full w-full object-cover" alt="Brief Mockup" />
                    </div>

                    <div className="flex w-full flex-col items-start px-6 py-4 text-center md:py-8 md:text-left">
                        <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-1">
                            <div className="h-16 w-12">
                                <BriefSvg isHovered={hoveredBrief} scrolling={scrolling} isMobile={isMobile} />
                            </div>
                            <h2 className="text-2xl font-bold text-white md:text-3xl">Brief</h2>
                        </div>
                        <p className="mt-2 text-left text-sm text-neutral-300 md:text-base">
                            It all starts with a brief! Add your client’s briefs (or send the form to them to complete),
                            and have access to matched properties instantly.
                        </p>
                    </div>
                </div>

                <div
                    className="flex w-full flex-col overflow-hidden rounded-2xl bg-[#292929] shadow-lg"
                    onMouseEnter={() => setHoveredAlerts(true)}
                    onMouseLeave={() => setHoveredAlerts(false)}
                >
                    <div className="h-72 w-full flex-shrink-0">
                        <img src={alertsMockup} className="h-full w-full object-cover object-top" alt="Alerts Mockup" />
                    </div>

                    <div className="flex w-full flex-col items-start px-6 py-4 text-center md:py-8 md:text-left">
                        <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-1">
                            <div className="h-12 w-12">
                                <AlertsSvg isHovered={hoveredAlerts} scrolling={scrolling} isMobile={isMobile} />
                            </div>
                            <h2 className="text-2xl font-bold text-white md:text-3xl">Alerts</h2>
                        </div>

                        <p className="mt-2 text-left text-sm text-neutral-300 md:text-base">
                            As soon as your brief’s are added, our system will alert you to properties that match the
                            brief! Easily access DD reports, enquire or offer straight from the alert!
                        </p>
                    </div>
                </div>

                <div
                    className="flex w-full flex-col overflow-hidden rounded-2xl bg-[#292929] shadow-lg"
                    onMouseEnter={() => setHoveredClose(true)}
                    onMouseLeave={() => setHoveredClose(false)}
                >
                    <div className="h-72 w-full flex-shrink-0">
                        <img src={closeMockup} className="h-full w-full object-cover object-top" alt="Close Mockup" />
                    </div>

                    <div className="flex w-full flex-col items-start px-6 py-4 text-center md:py-8 md:text-left">
                        <div className="flex items-center gap-4 lg:flex-col lg:items-start lg:gap-1">
                            <div className="h-12 w-12">
                                <CloseSvg isHovered={hoveredClose} scrolling={scrolling} isMobile={isMobile} />
                            </div>
                            <h2 className="text-2xl font-bold text-white md:text-3xl">Close</h2>
                        </div>

                        <p className="mt-2 text-left text-sm text-neutral-300 md:text-base">
                            Close deals FAST! Offers will be sent directly to the Selling Agent via push notification,
                            meaning from brief to alert you could close a deal in minutes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForBuyingAgent;
