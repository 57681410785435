import React from 'react';
import Lottie from 'react-lottie';
import animationData from './assets/lotties/network-effect.json';
import styles from './NetworkEffect.module.scss';

const NetworkEffect = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="flex w-full items-center px-4 py-20 md:px-20">
            <div className="flex justify-center overflow-hidden rounded-2xl bg-[#292929] p-6 md:px-12 ">
                <div className="flex flex-col gap-4 p-1 md:p-4">
                    <div className="flex flex-col items-center gap-4">
                        <h1 className="flex flex-col gap-x-4 text-center text-3xl font-bold text-white md:text-[48px] lg:flex-row">
                            The Realty{' '}
                            <span className="bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text text-transparent">
                                Network Effect
                            </span>
                        </h1>
                        <h2 className="text-center text-base font-normal leading-8 text-neutral-300 md:text-lg">
                            Streamline your communications with Realty Live.
                        </h2>
                    </div>

                    <div className="relative h-0 w-full overflow-hidden pb-[60.25%] md:pb-[60.25%] lg:pb-[38.25%]">
                        <div className={styles.lottieContainer}>
                            <Lottie options={defaultOptions} width="100%" height="100%" />
                        </div>
                    </div>

                    <h2 className="text-center text-base font-normal leading-8 text-neutral-300 md:text-lg">
                        Realty's
                        <span className=" ml-2 bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text font-bold text-transparent">
                            network effect
                        </span>
                        , connects buying and selling agents through listings and briefs. Enabling
                        <span className="mx-2 bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text font-bold text-transparent">
                            simplified communications
                        </span>
                        creates a
                        <span className="mx-2 bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text font-bold text-transparent">
                            powerful property ecosystem
                        </span>
                        in a
                        <span className="ml-2 bg-gradient-to-r from-purple-500 from-10% via-purple-500 via-30% to-purple-600 to-90% bg-clip-text font-bold text-transparent">
                            2-sided marketplace.
                        </span>
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default NetworkEffect;
