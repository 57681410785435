import React from 'react';
import styles from './RecordSvg.module.scss';
interface RecordSvgProps {
    isHovered: boolean;
    scrolling: boolean;
    isMobile: boolean;
}

const RecordSvg = ({ isHovered, scrolling, isMobile }: RecordSvgProps) => {
    const shouldTilt = isMobile && scrolling; // Trigger shake on mobile scroll

    return (
        <svg
            className={`${styles.record} ${shouldTilt || isHovered ? styles.tilt : ''}`}
            width="73"
            height="110"
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 73 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M60 110H13C5.832 110 0 104.168 0 97V13C0 5.831 5.832 0 13 0H60C67.168 0 73 5.832 73 13V97C73 104.168 67.168 110 60 110ZM13 5.999C9.141 5.999 6 9.139 6 12.999V96.999C6 100.859 9.141 104 13 104H60C63.859 104 67 100.859 67 96.999V12.999C67 9.139 63.859 5.999 60 5.999H13Z"
                fill="white"
            />
            <path
                d="M36 100C38.2091 100 40 98.2091 40 96C40 93.7909 38.2091 92 36 92C33.7909 92 32 93.7909 32 96C32 98.2091 33.7909 100 36 100Z"
                fill="white"
            />
            <path
                d="M60 88H13C11.896 88 11 87.104 11 86V12C11 10.896 11.896 10 13 10H60C61.104 10 62 10.896 62 12V86C62 87.104 61.104 88 60 88ZM15 84H58V14H15V84Z"
                fill="white"
            />
            <path
                className={`${styles.record} ${shouldTilt || isHovered ? styles.beat : ''}`}
                d="M29.5 59.5013C29.17 59.5013 28.85 59.4213 28.55 59.2613C27.9 58.9113 27.5 58.2413 27.5 57.5013V35.5013C27.5 34.7913 27.88 34.1313 28.49 33.7713C29.1 33.4113 29.86 33.4113 30.48 33.7613L48.55 43.9713C49.16 44.3113 49.54 44.9513 49.56 45.6513C49.58 46.3513 49.24 47.0113 48.65 47.3913L30.58 59.1813C30.25 59.4013 29.87 59.5013 29.49 59.5013H29.5ZM31.5 38.9313V53.8113L43.72 45.8413L31.5 38.9313Z"
                fill="white"
            />
        </svg>
    );
};

export default RecordSvg;
