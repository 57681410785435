import React from 'react';
import styles from './BriefSvg.module.scss';

interface BriefSvgProps {
    isHovered: boolean;
    scrolling: boolean;
    isMobile: boolean;
}

const BriefSvg = ({ isHovered, scrolling, isMobile }: BriefSvgProps) => {
    const shouldBounce = isMobile && scrolling;
    return (
        <svg
            className={`${styles.brief} ${shouldBounce || isHovered ? styles.bounce : ''}`}
            width="93"
            height="97"
            style={{ width: '100%', height: '100%' }}
            viewBox="0 0 93 97"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M86 97H23C19.14 97 16 93.859 16 90V79C16 77.343 17.343 76 19 76C20.657 76 21.844 77.343 21.844 79V90C21.844 90.542 22.458 91 23 91H86C86.542 91 87 90.542 87 90V32.106C87 31.561 86.544 31.001 86 31H75C73.343 30.997 71.997 29.736 71.999 28.079C72.002 26.424 73.344 25.084 74.999 25.084C75.001 25.084 75.003 25.084 75.003 25.084L86.003 25.101C89.86 25.106 92.843 28.144 92.843 32.001V90.001C92.845 93.86 89.86 97 86 97Z"
                fill="white"
            />
            <path
                d="M68.925 79.9999H7C3.691 79.9999 1 77.3089 1 73.9999V16.9999C1 13.6939 3.689 11.0059 6.995 11.0059L68.928 11.0979C72.235 11.1019 74.845 13.6929 74.845 16.9999V73.9999C74.845 77.3089 72.233 79.9999 68.925 79.9999ZM6.997 15.0059C5.895 15.0059 5 15.9009 5 16.9999V73.9999C5 75.1029 5.897 75.9999 7 75.9999H68.924C70.027 75.9999 71 75.1029 71 73.9999V16.9999C71 15.8959 70.104 15.0019 69 14.9999L6.997 15.0059Z"
                fill="white"
            />
            <path
                d="M37 28C32.374 28 28 23.626 28 19V8C28 3.364 31.364 0 36 0H37C41.86 0 45 3.532 45 9C45 10.657 43.657 12 42 12C40.343 12 39 10.657 39 9C39 6 37.649 6 37 6H36C34.673 6 34 6.673 34 8V19C34 20.317 35.682 22 37 22C38.657 22 40 23.343 40 25C40 26.657 38.657 28 37 28Z"
                fill="white"
            />
            <path
                d="M29 43H16C14.896 43 14 42.104 14 41C14 39.896 14.896 39 16 39H29C30.104 39 31 39.896 31 41C31 42.104 30.105 43 29 43Z"
                fill="white"
            />
            <path
                d="M63 43H40C38.896 43 38 42.104 38 41C38 39.896 38.896 39 40 39H63C64.104 39 65 39.896 65 41C65 42.104 64.105 43 63 43Z"
                fill="white"
            />

            <path
                className={`${styles.brief} ${shouldBounce || isHovered ? styles.draw : ''}`}
                d="M47 53H16C14.896 53 14 52.104 14 51C14 49.896 14.896 49 16 49H47C48.104 49 49 49.896 49 51C49 52.104 48.105 53 47 53Z"
                fill="white"
            />
            <path
                className={`${styles.brief} ${shouldBounce || isHovered ? styles.draw : ''}`}
                d="M55 64H16C14.896 64 14 63.104 14 62C14 60.896 14.896 60 16 60H55C56.104 60 57 60.896 57 62C57 63.104 56.105 64 55 64Z"
                fill="white"
            />
            <path
                d="M21 81.9999H7C3.14 81.9999 0 78.8589 0 74.9999V16.9999C0 15.1339 0.729 13.3779 2.055 12.0549C3.378 10.7339 5.132 10.0059 6.995 10.0059C6.998 10.0059 7.001 10.0059 7.004 10.0059L70.005 10.0999C73.862 10.1049 77 13.1419 77 16.9999V29.9999H71.001V17.1059C71.001 16.5609 70.544 16.0009 69.872 15.9639L7 15.9999C6.781 16.0359 6.406 16.1899 6.295 16.3009C6.185 16.4099 5.845 16.6479 5.845 17.0009V75.0009C5.845 75.5429 6.459 76.0009 7.001 76.0009H21.001L21 81.9999Z"
                fill="white"
            />
        </svg>
    );
};

export default BriefSvg;
