import React from 'react';

import HeroRealtyApp from './components/HeroRealtyApp';
import PartnersLogo from './components/PartnersLogo';
import AppFeature from './components/AppFeature';
import ForSellingAgent from './components/ForSellingAgent';
import ForBuyingAgent from './components/ForBuyingAgent';
import NetworkEffect from './components/NetworkEffect';
import FaQ from './components/FaQ';
import RealtyLiveLayout from '../../layouts/RealtyLiveLayout';

import { useQuery } from '@apollo/client';
import DailyNewCurrentPropertyCount from '~/queries/DailyNewCurrentPropertyCount.graphql';
import AgentBriefs from '~/queries/AgentBriefs.graphql';

const LiveStreamLandingPage = () => {
    // const title = 'Realty Live | Realty.com.au';
    // const description =
    //     'A broadcast revolution in the palm of your hand. Instantly connect with buyers, sellers and tenants, chat with them and go LIVE where and when you want. RealtyLive will promote your broadcast, stream it and have it available for catchup anytime.';
    // const title = `Property Marketplace for Buyers Agents & Professionals | Realty.com.au`;

    const { data } = useQuery(DailyNewCurrentPropertyCount, { ssr: false });
    const { adminPropertyStatistics } = data || {};
    const { dailyNewCurrentPropertyCount } = adminPropertyStatistics || {};
    const totalCurrentPropertyDaily = dailyNewCurrentPropertyCount?.at(-1)?.total ?? 0;

    const { data: briefsData } = useQuery(AgentBriefs, {
        ssr: false,
        variables: {
            options: {
                isActive: true,
                isIncludeTestAgency: false,
            },
        },
    });
    const totalAgentBriefs = briefsData?.agentBriefsConnection?.totalCount ?? 0;

    return (
        <div className="flex justify-center   bg-[#1b1b1b]">
            <RealtyLiveLayout>
                <div className="flex w-full max-w-screen-2xl flex-col items-center">
                    <HeroRealtyApp propertyTodayCount={totalCurrentPropertyDaily} totalAgentBriefs={totalAgentBriefs} />
                    <PartnersLogo />
                    <NetworkEffect />
                    <AppFeature />

                    <ForSellingAgent />
                    <ForBuyingAgent />

                    <FaQ />
                </div>
            </RealtyLiveLayout>
        </div>
    );
};

export default LiveStreamLandingPage;
